import React from 'react';
import {graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';

import Layout from '../components/Layout';
import {Container} from '../components/Grid';

import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import FAQ from '../components/Page/FAQ';
import PageHero from '../components/shared/hero';
import WorkWithUs from '../components/Page/home/WorkWithUs';
import BookingProcess from '../components/Page/home/BookingProcess';
import {Button} from '../components/Button';
import BookNowController from '../components/BookNowController';
import Reviews from '../components/Page/home/Reviews/Reviews';
import {
  Description,
  StyledHeading,
} from '../components/Page/office-cleaning/Included';

const StyledBlock = styled.div`
  padding: 35px;
  background-color: #f8f9f8;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.screens.lg} {
    padding: 100px;
    margin-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;
  margin-top: 27px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const DescriptionText = styled.p`
  font-family: Quicksand;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
  }
`;

export const BookButton = ({buttonText}) => {
  return (
    <BookNowController>
      <StyledButton>{buttonText}</StyledButton>
    </BookNowController>
  );
};
export const DescriptionBlock = ({content, button_text}) => {
  return (
    <Container>
      <StyledBlock>
        <DescriptionText>{content}</DescriptionText>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
          }}
        >
          <BookButton buttonText={button_text} />
        </div>
      </StyledBlock>
    </Container>
  );
};

const work_with_us = {
  title2: 'ironing_working_with_us_heading',
  items: [
    {
      title: 'ironing_working_with_us_item_1_header',
      text: 'ironing_working_with_us_item_1_content',
    },
    {
      title: 'ironing_working_with_us_item_2_header',
      text: 'ironing_working_with_us_item_2_content',
    },
    {
      title: 'ironing_working_with_us_item_3_header',
      text: 'ironing_working_with_us_item_3_content',
    },
    {
      title: 'ironing_working_with_us_item_4_header',
      text: 'ironing_working_with_us_item_4_content',
    },
    {
      title: 'ironing_working_with_us_item_5_header',
      text: 'ironing_working_with_us_item_5_content',
    },
    {
      title: 'ironing_working_with_us_item_6_header',
      text: 'ironing_working_with_us_item_6_content',
    },
  ],
};

const process = {
  title1: 'ironing_process_heading',
  description: '',
  items: [
    {
      title: 'ironing_process_item_1_title',
      text: 'ironing_process_item_1_text',
    },
    {
      title: 'ironing_process_item_2_title',
      text: 'ironing_process_item_2_text',
    },
    {
      title: 'ironing_process_item_3_title',
      text: 'ironing_process_item_3_text',
    },
    {
      title: 'ironing_process_item_4_title',
      text: 'ironing_process_item_4_text',
    },
  ],
};

const IroningPage = ({data}) => {
  const {language, t} = useI18next();
  const {background, backgroundMobile, backgroundTablet, faq, reviews} = data;
  return (
    <Layout headerStyle="homeCleaning">
      <SEO
        title={t('seo_title')}
        description={t('seo_description')}
        lang={language}
        keywords={t('seo_keywords', {returnObjects: true})}
      />
      <PageHero
        imgPosition={'80% 0%'}
        content={{
          heading: t(`ironing_hero_heading`),
          text: t(`ironing_hero_description`),
          button: t(`ironing_hero_button`),
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <WorkWithUs
        content={work_with_us}
        bottomDescription={t(``)}
        customDescription={t(`ironing_working_with_us_description`)}
        headingMarginBottom={20}
      />
      <div style={{marginBottom: 50}} />
      <DescriptionBlock
        content={t(`ironing_description`)}
        button_text={t(`ironing_description_button`)}
      />
      <BookingProcess
        content={process}
        bottomDescription="ironing_process_bottom_text"
        bottomButton="ironing_process_bottom_button"
      />
      <div style={{marginBottom: 100}} />
      <Container>
        <StyledHeading>{t(`ironing_price_heading`)}</StyledHeading>
        <Description
          marginBottom="70px"
          width="840px"
          dangerouslySetInnerHTML={{
            __html: t(`ironing_price_text`),
          }}
        />
      </Container>
      <div style={{marginBottom: 100}} />
      <Reviews reviews={reviews.edges} headerStyle={{marginLeft: 0}} />
      <FAQ faqs={faq.edges} headingProps={{marginLeft: 0}} />
      <div style={{marginBottom: 100}} />
      <DescriptionBlock
        content={t(`ironing_invest_block_text`)}
        button_text={t(`ironing_invest_block_button`)}
      />
      <div style={{marginBottom: 150}} />
    </Layout>
  );
};

export default IroningPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "ironing", "seo_ironing", "homepage"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "homepage"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
    background: file(relativePath: {eq: "ironing-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "ironing-hero-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "ironing-hero-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    reviews: allContentfulReviews(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          comment {
            comment
          }
          rating
          title
          id
          name
          icon {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
